<template>
  <custom-collapse
    class="assignment-courier"
    open>
    <template #header>
      <div class="assignment-courier-header">
        <div class="courier-details">
          {{ courier.name }}
          <template v-if="!isThirdParty">
            •
            {{ courier.mobileNumber }}
          </template>
        </div>
        <div class="courier-delivery-status">
          <b-icon
            class="active-indicator"
            :class="{'inactive': isThirdParty}"
            icon="circle"
            size="is-small"/>
          {{ $tc('assignment.rightPanel.ordersLeft', firstTripOrdersCount, { count: firstTripOrdersCount }) }}
        </div>
      </div>
      <span
        class="edit-courier-trips"
        @click.stop="editCourierTrips">
        {{ $t('assignment.rightPanel.edit') }}
      </span>
    </template>
    <div class="assignment-courier-trips">
      <assignment-trip
        v-for="trip in courierTrips"
        :key="`assignment_courier_trip_${trip.id}`"
        :trip="trip"
        :statusVisible="true"
        @trip-contextmenu="(event, trip) => $emit('trip-contextmenu', event, trip)"
        @task-contextmenu="(event, task) => $emit('task-contextmenu', event, task)"/>
    </div>
    <div
      v-if="courierTrips.length === 0"
      class="assignment-courier-trips-empty">
      {{ $t('assignment.rightPanel.emptyTrips') }}
    </div>
  </custom-collapse>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const AssignmentTrip = () => import('@components/AssignmentTrip')
const CustomCollapse = () => import('@components/CustomCollapse')

export default {
  name: 'assignment-courier',
  components: {
    AssignmentTrip,
    CustomCollapse
  },
  props: {
    courier: {
      type: Object,
      required: true
    },
    isThirdParty: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('trip', [
      'getCourierTrips',
      'getCourierTripsClone'
    ]),
    courierTrips() {
      return this.getCourierTrips(this.courier.id)
    },
    firstTripOrdersCount() {
      const firstTrip = this.courierTrips[0]
      if (firstTrip) {
        return firstTrip.tasks.length
      }
      return 0
    }
  },
  methods: {
    ...mapActions('courier', [
      'setSelectedCourier'
    ]),
    ...mapActions('trip', [
      'setAssigningTrips'
    ]),
    editCourierTrips() {
      this.setSelectedCourier(this.courier)
      this.setAssigningTrips(this.getCourierTripsClone(this.courier.id))
    }
  }
}
</script>

<style lang="scss" scoped>
.assignment-courier {
  &.open {
    &::v-deep .custom-collapse-header {
      color: $grey-light;
      border-bottom: 1px solid $grey-darker;
    }
  }

  &::v-deep .custom-collapse-header .toggle-collapse-icon,
  .edit-courier-trips {
    position: relative;
    top: 3px;
    align-self: flex-start;
  }

  .assignment-courier-header {
    flex-grow: 1;

    > :not(.courier-details) {
      font-weight: normal;
      @extend %body;
    }

    .courier-delivery-status {
      position: relative;
      left: -16px;
      padding-top: $space-xxs;
      color: $grey;

      .active-indicator {
        position: relative;
        top: 6px;
        width: 0.5rem;
        height: 0.5rem;
        margin-right: $space-xxs;
        font-size: 0.5rem;
        color: $green;
        vertical-align: text-top;

        &.inactive {
          color: $grey;
        }
      }
    }
  }

  .edit-courier-trips {
    font-weight: normal;
    color: $grey-lighter;
    text-decoration: underline;
    @extend %small;
  }

  .assignment-courier-trips-empty {
    padding: $space-xs;
  }
}
</style>
